
<template>
  <div class="addons">
    <div class="row">
      <div class="col-12">
        <h3 class="my-3"> Addons </h3>
        <div class="row mb-2">
          <div class="col-sm-12 col-md-8 mb-2 mb-md-0">
            <form class="form-inline">
              <label class="d-inline-flex mb-0 mr-2 align-items-center">
                  <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              </label>
              <div class="form-group mb-0 mr-2">
                <select id="status-select" @change="fetchItems()" v-model="defaultModel"  class="custom-select">
                  <option disabled selected>-Sort By-</option>
                  <option value='PRODUCT'>Products</option>
                  <option value='PLAN'>Plans</option>
                  <option :value='null'>Show all</option>
                </select>
              </div>
              <button class="btn text-white btn-primary" type="button" @click.prevent="popupModalShow = true">
                  <i class="mdi mdi-plus-circle"></i> Add Addon
              </button>
            </form>
          </div>
          <div class="col-sm-12 col-md-4">
              <div class="">
                  <label class="d-block">
                      <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                  </label>
              </div>
          </div>
        </div>
        <is-loading v-if="isLoading" />
        <div v-else class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <p class="mb-0">Showing {{paginationInfo}} results</p>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3" v-for="(addon, index) in pageData.data" :key="index">
                <div role="tablist" class="">
                  <b-card no-body class="mb-1 shadow-none">
                    <b-card-header header-tag="header" class="bg-transparent" role="tab">
                      <div class="d-sm-flex">
                        <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                          <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                            <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{addon.name}} - ${{addon.charge}}
                          </a>
                        </h5>
                        <span class="float-right">
                          <b-dropdown variant="light" size="sm" right>
                            <template slot="button-content">
                              <i class="mdi mdi-chevron-down"></i>
                            </template>
                            <b-dropdown-item-button @click="initEdit(addon)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                            <b-dropdown-item-button @click="deleteItem(addon.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                          </b-dropdown>
                        </span>
                      </div>
                    </b-card-header>
                    <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
                      <b-card-body>
                        <h6>Charge: ${{addon.charge}}</h6>
                        <b-card-text>Duration: {{calculateDeliveryTime(addon.duration)}}</b-card-text>
                        <b-card-text>{{addon.description}}</b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
              <div class="py-4 paging_simple_numbers">
                  <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                  :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
              </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode ? 'Update addon':'Add addon'"
      title-class="" @hide="initializeAll()">
      <addon-form v-if="popupModalShow" @closeMe="initializeAll()"
       :editMode="editMode" :editItem="showItem" />
    </b-modal>
  </div>
</template>

<script>
import AddonForm from '@/components/forms/AddonForm.vue';
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';

export default {
  components: {
    AddonForm,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      editMode: false,
      showItem: null,
      defaultModel: null,
      searchQuery:"",
      pageOptions: [10, 25, 50, 100],
    }
  },
  computed: {
    pageData(){
      return this.$store.state.addon.pageData
    },
    reviews() {
      return this.pageData.data
    },
    currentPage: {
      get() {
          return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultModel){ payload.model = this.defaultModel }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("addon/fetchItems", payload)
      .then(response => this.isLoading = false)
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this addon!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/addons/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
               this.$store.commit("addon/DELETE_ITEM", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.showItem = {...item}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.showItem = null;
      this.popupModalShow = false;
    },
  },
  mounted(){
    this.fetchItems()
  }

}
</script>
